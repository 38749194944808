import React from 'react'
import PrivateLayout from '../layouts/private'
import MessagesDuSoir from '../components/pages/MessagesDuSoir'

const MessagesDuSoirPage = (props) => {
  return <PrivateLayout><MessagesDuSoir /></PrivateLayout>
}

MessagesDuSoirPage.propTypes = {

}

MessagesDuSoirPage.defaultProps = {

}

export default MessagesDuSoirPage
