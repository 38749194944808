import React from 'react'
import { FormattedDate } from 'react-intl'
import { Link } from 'gatsby'
import { Wrapper, Back, Content, Messages, Message, CreationDate } from './index.styles'
import MessagesDuSoirState from './index.state'
import Loader, { Loader100vh } from '../../UI/Loader'
import { Favorite, Previous } from 'grommet-icons'

const MessagesDuSoir = (props) => {

  const { loading, messages } = MessagesDuSoirState.useContainer()
  if (loading) return <Loader100vh />
  return <Wrapper>
    <Back>
      <Link to='/'><Previous color='#fff' size='30px' /></Link>
    </Back>
    <Content>
      <Messages>
        { messages.map(m => <Link to={`/message/${m.id}`}><Message key={m.id}>
          <div>
            <span>
              <Favorite color='#fff' />
            </span>
            <span>Amour</span>
          </div>
          <CreationDate>
            <FormattedDate value={m.date.seconds * 1000} />
          </CreationDate>
          <div>
            <div>{m.author}</div>
            <div>Message</div>
          </div>
        </Message></Link>)}
      </Messages>
    </Content>
  </Wrapper>
}

MessagesDuSoir.propTypes = {

}

MessagesDuSoir.defaultProps = {

}

export default () => <MessagesDuSoirState.Provider>
  <MessagesDuSoir />
</MessagesDuSoirState.Provider>
