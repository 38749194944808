import styled from "styled-components";
import { colors } from "../../../theme";

export const Wrapper = styled.div`
  position: relative;
  background-image: linear-gradient(to left, ${colors.primary}, ${colors.secondary});
  padding: 70px 0 80px 0;
  height: 100%;
`

export const Back = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Content = styled.div`
  max-width: 450px;
  margin: 0 auto;
  padding: 0 30px 0 45px;
  height: 100%;
`

export const Messages = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 10px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

export const Message = styled.div`
  position: relative;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  > :first-child {
    display: flex;
    align-items: center;
    font-family: 'Dancing Script', cursive;
    font-weight: bold;
    color: #E36779;
    font-size: 24px;
    > :first-child {
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #4EC85B;
      border-radius: 6px;
      margin-right: 8px;
    }
  }
  > :last-child {
    margin-top: 6px;
    > :first-child {
      font-weight: bold;
      margin-bottom: -3px;
    }
  }
`

export const CreationDate = styled.div`
  position: absolute;
  top: 10px;
  right: 14px;
`
