import { createContainer } from 'unstated-next'
import { useState, useEffect } from "react"
import FirebaseState from '../../../state/FirebaseState'

const useMds = () => {
  const { firebase } = FirebaseState.useContainer()
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    firebase
    .firestore()
    .collection("mds")
    .get().then((snapshot) => {
      if (snapshot.docs.length === 0) return
      setMessages(snapshot.docs.map(d => ({ id: d.id, ...d.data() })).sort((a, b) => b.id - a.id))
      setLoading(false)
    })
  }, [])


  return { loading, messages }
}

const MessagesDuSoirState = createContainer(useMds)

export default MessagesDuSoirState